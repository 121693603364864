import React, {useState} from "react";
import Header from "@Components/Header/Header"
import JobListingPage from "@Components/JobListing/JobListing"
import { Link } from "@StarberryUtils"
import { Row, Col, Container, Modal } from 'react-bootstrap';
// import '../Components/JobListing/Technology/Technology.Scss'
import { Button, Card, Accordion, Form } from "react-bootstrap";
import FilterSearch from '@Components/JobListing/FilterSearch/FilterSearch'
import JobAlert from "@Components/JobListing/JobAlert/JobAlert";
import { CANDIDATE_PAGE_URL } from "@Components/common/site/constants";
import FooterPage from "@Components/Footer/Footer"
import ApplyModal from "./ApplyModel"
import $ from "jquery";
import qs from "qs"
import ScrollAnimation from 'react-animate-on-scroll'
import algoliasearch from "algoliasearch/lite"
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    connectSortBy,
} from "react-instantsearch-dom"
import Cookies from 'universal-cookie'
import { numberWithCommas } from "@Components/common/site/common-functions"
const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

const searchClient = algoliasearch(
   process.env.GATSBY_ALGOLIA_APP_ID,
   process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
const InfiniteHits = ({
   hits,
   hasPrevious,
   refinePrevious,
   hasMore,
   refineNext,
   location,
}) => (
   <>
       <Row>
           <Col lg={12} className="text-center">
           {(hits.length > 0 && hasPrevious) && (
               <button
               id="myprevbut"
               onClick={refinePrevious}
               disabled={!hasPrevious}
               className="btn btn-link text-uppercase text-white d-none"
               >
               Show prev
               </button>
           )}
           </Col>
       </Row>
       {
            hits.map((hit, i) => {
               return (
                  <>
                  <div className="job">
                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                    <h2><Link to={'detail/'+hit.objectID}>{hit.posting_title}</Link></h2>
                    {hit.city && hit.state &&
                    <div className="job-info">
                            <i className="icon-location"> </i>
                        <span>{hit.city}{hit.city ? ', ' : ''} {hit.state}</span>
                    </div>
                    }
                    {hit.job_type &&
                    <div className="job-info">
                            <i className="icon-clock">
                            </i>
                        <span>{hit.job_type}</span>
                    </div>
                    }
                    {hit.salary &&
                    <div className="job-info">
                            <i className="icon-money">
                            </i>
                        <span>{numberWithCommas(hit.salary)} {hit.currency}</span>
                    </div>
                    }
                    {/* <ReactMarkdown source={hit.job_description} allowDangerousHtml /> */}
                    <p>{hit.job_description?.replace(/<[^>]+>/g, '').substring(0, 300)}{hit.job_description?.length > 300 ? '...': ''}</p>
                    <div className="buttons">
                       <ApplyModal job_url={process.env.GATSBY_SITE_URL+"for-candidates/job-details/"+hit.objectID} posting_title={hit.posting_title} />
                        {/* <a onClick={() => handleShow(hit.posting_title)}  className="btn">
                            <i className="icon-cross"> </i>
                            quick apply
                        </a> */}
                        {/* <a href="#" className="like-us">
                            <i className="icon-like">
                            </i>
                            <span className="sm-text d-none d-md-block">
                                shortlist
                         </span>
                        </a> */}
                    </div>
                </ScrollAnimation>
                </div>
                  {/* {i == 2 ? <JobAlert /> : ""} */}
                  </>
                  )
            })
        }
       {(hits.length > 0 && hasMore) && (
          <Col lg={12} className="d-flex justify-content-center py-5">
            <button
              id="mynextbut"
              onClick={refineNext}
              disabled={!hasMore}
              className="btn property-search-load-more"
            >
                Show more
            </button>
          </Col>
        )}
    </>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

// Property list loop

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
   return (
       <>
       {nbHits === 0 &&
               <div className="no-result-blk">No results have been found.</div>
       }
       </>
   )
}

const CustomNoStats = connectStats(NoStats)

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Search box
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
   
   // sessionStorage.getItem('search')
   return (
      //  <Form.Group className="form-group">
      //      {/* <div className="icon"><Search /></div> */}
      //      <Form.Control id="search" name="search" type="text" placeholder="Notting Hill, London" className="search-location"
      //          onChange={event => refine(event.currentTarget.value)}
      //          value={currentRefinement.replace(/-/g, ' ')}
      //       />
      //  </Form.Group>
            <div className="tab-content tablet d-none d-md-block">
              <Form className="tab-form">
                {/* <Form.Control type="Text" placeholder="Technology" className="input-field technology-filed" /> */}

                <Form.Control autoComplete="off" id="search" name="search" type="text" placeholder="Job title, position or location" className="input-field"
                onChange={event => refine(event.currentTarget.value)}
                         value={currentRefinement.replace(/-/g, ' ')}
                 />
                <Link className="search">
                  <i className="icon-search">
                  </i>
                </Link>
              </Form>
            </div>
   )
})
// Search box

const JobListing = (props) => {
    const [show, setShow] = useState(false);

   const showFilter =()=>{
       setShow(!show);
   }
   
   const closeFilter =()=>{
      setShow(false)
  }


   return (
      <InstantSearch
      indexName={index_name}
      searchClient={searchClient}
      >
         <div className="header-search ">
                             <SearchBox />
                             </div>
      <div className="wrapper slide">
         <div className="joblisting-page-header jobdetails-header"><Header nobanner={true} /></div>
         
         <section className="job-listing-page">
                <div className="mobile-filter">
                </div>
                <Container>
                    <Row>
                        <Col>
                        <div className="technology">
            {/* <FilterSearch closeFilter={closeFilter} show={show}/> */}
            
            <span className="sm-content d-none d-md-block">
            <Link to={'/'}>ALLSOPP & ALLSOPP RECRUITMENT</Link> / FIND A JOB
            </span>

            <h2>
            <CustomStats /> Jobs 
              </h2>
            <div className="about-technology">

                <div className="content">
                    <p>
                        Find the latest technology jobs, including IT Jobs, IT support positions and internet jobs.
              </p>
                </div>
                <div className="job-alert">
                    {/* <div className="message">
                        <i className="icon-msg"></i>
                        Get new job alerts
                                                </div> */}
                    <div className="sort-by">
                        <span className="sort-list">
                            Sort by:
                           </span>
                           <span>
                           <SortBy
                                defaultRefinement={index_name}
                                className="search-sory-by"
                                items={[
                                    // { value: index_name+"_most_recent", label: "Most Recent First" },
                                    { value: index_name, label: "Most Recent First" },
                                    { value: index_name+"_most_oldest", label: "Most Oldest" },
                                ]}
                            />
                            </span>
                    </div>
                </div>
            </div>
            <div className="filter d-lg-none">
                <a onClick={showFilter} className="btn">
                    <i className="icon-slider">

                    </i>
                    filter results
              </a>
            </div>
        </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} xl={3} className=" d-none d-lg-block p-xl-0">
                            <div className="desktop-filter">
                            <div className={props.show ? "filter-search" : "hide"}>
            <h2 className="d-lg-none">Filter Your Search</h2>
            <i onClick={props.closeFilter} className="icon-close">

            </i>
            {/* <Form className="tab-form d-md-none">
                                            <Form.Control type="Text" placeholder="Job title, position or location" />
                                            <Link href="#" className="search">
                                                <i className="icon-search">
                                                </i>
                                            </Link>
                                        </Form> */}
                                        {/* <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    Industry
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                        
                            <RefinementList
                                    attribute="industry"
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    Locations
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                        
                            <RefinementList
                                    attribute="city"
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    Type of Contract
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                        
                            <RefinementList
                                    attribute="job_type"
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    
                    </Accordion> */}
<div className="btn-wrapper d-md-none">
                    <a className="btn cancel" href="#">
                        cancel
                   </a>
                    <a className="btn btn-primary save" href="#">
                        apply filters
                   </a>
                </div>
        </div>
                            </div>
                                  
                        </Col>
                        <Col lg={8} xl={9} className="ml-auto">
                        {/* <InstantSearch
                        indexName={index_name}
                        searchClient={searchClient}
                        // searchState={this.state.searchState}
                        // onSearchStateChange={this.onSearchStateChange}
                        // createURL={createURL}
                        // routing="true"
                    > */}
                        <div className="d-none">
                        <RefinementList
                                attribute="publish"
                                defaultRefinement={["true"]}
                            />
                        </div>
                                                        
                        <CustomInfiniteHits />
                        <CustomNoStats />

                    {/* </InstantSearch> */}
                            {/* <JobPage data={props.data} /> */}

                        </Col>
                    </Row>
                </Container>
                </section>
         {/* <JobListingPage data={data?.glstrapi?.jobs} /> */}
         <FooterPage />
        
      </div>
      
      </InstantSearch>
   )
};
export default JobListing